import React, { useState, useEffect } from "react"

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


/* Import Local Style(s) */
import "./mapZoom.scss"

const MapZoom = ({guideImage, guideImageMobile, zoomLevel, zoomLevelMobile}) => {

  

  const calculateCentering = (e) => {
    console.log(e)

    const imgWidth = 882
    const imgHeight = 4035
    const width = window.innerWidth - 30

    /*
        image actual: 2715 x 12307
        image scaled: 882  x  4000
  
        
        container height: 450px
        container y = width
    */

    // get scale to make 100% of screen
    const scale = width/imgWidth 

    // get scaled height
    const scaledHeight = scale * imgHeight
    const offsetY = (scaledHeight - 450) * -1

    console.log('scale:', scale)
    console.log('scaledHeight:', scaledHeight)
    console.log('offsetY:', offsetY)

    e.setTransform(3, offsetY, scale, 0, 'easeOut')

  }


	return (
		<div className="map_zoom">
			
      <img 
        className="desktop_map"
        src={guideImage?.asset?.url} 
        alt="image of a map of the Untitled Art Fair outlining where each booth is" 
      />
        

      <TransformWrapper
				initialScale={1}
        limitToBounds={true}
        initialPositionY={-1120}
        minScale={.38}
        maxScale={1}
        wheel={{ disabled: true }}
        onInit={calculateCentering}
			>
        <TransformComponent 
          wrapperClass="mbl_map_zoom_transform" 
          contentClass="mbl_map_zoom_content"
          contentStyle={{width: 882, height: 4000}}
        >
          <img 
            height="4000"
            width="882"
            src={guideImageMobile?.asset?.url} alt="image of a map of the Untitled Art Fair outlining where each booth is" />
        </TransformComponent>
      </TransformWrapper>

		</div>
	)

}

export default MapZoom